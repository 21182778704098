import {PaginationLogic} from '@src/commonlogic/PaginationLogic';
import {Pagination} from '@src/models/pagination';
import {useEffect, useState} from 'react';
import {InfiniteData} from 'react-query';

export const useDataLength = <T>(
  data: InfiniteData<Pagination<T>> | undefined
): number => {
  const [dataLength, setDataLength] = useState(0);

  useEffect(() => {
    setDataLength(PaginationLogic.handleCalculateDataLength(data));
  }, [data]);

  return dataLength;
};
