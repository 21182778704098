import styled from 'styled-components';
import {Spinner} from './Spinner';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ListSpinner = () => {
  return (
    <Container>
      <Spinner />
    </Container>
  );
};
