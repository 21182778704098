import Image from 'next/image';
import React, {useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'next-i18next';
import {useRouter} from 'next/router';
import {authService} from '@src/features/auth/logic/auth-service';

const settings = [
  {text: 'data_protection', path: 'https://www.membplace.com/datenschutz'},
  {text: 'imprint', path: 'https://www.membplace.com/impressum'},
  {text: 'legal', path: 'https://www.membplace.com/nutzungsbestimmung'},
];

export const SettingsButton = ({
  isAdmin,
  isOpen,
  setIsOpen,
}: {
  isAdmin?: boolean;
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
}) => {
  const {t} = useTranslation();
  const router = useRouter();

  const handleSettingsClick = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = (path: string) => {
    router.push(path);
  };

  const handleLogout = () => {
    authService.logout();
    router.push('/sign-in');
  };

  return (
    <Wrapper>
      <Image
        alt="Settings"
        src={'/icons/settings.svg'}
        width={24}
        height={24}
        onClick={handleSettingsClick}
      />
      {isOpen ? (
        <ModalContainer>
          <Image
            src={'/icons/arrow-up.svg'}
            width={20}
            onClick={handleSettingsClick}
            height={20}
            style={{
              position: 'absolute',
            }}
            className="settings-button-icon-positioning"
            alt="arrow"
          />
          {!isAdmin &&
            settings.map((it) => (
              <Link key={it.path} onClick={() => handleLinkClick(it.path)}>
                {t(`common:settings:${it.text}`)}
              </Link>
            ))}
          <Link onClick={handleLogout}>{t('common:logout')}</Link>
        </ModalContainer>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;
const ModalContainer = styled.div`
  position: absolute;
  border-radius: 10px;
  background-color: ${(p) => p.theme.colors.neutral100};
  z-index: 100;
  box-shadow: ${(p) => p.theme.shadow.shadow1};
  transform: translateX(-235px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 270px;
  margin-top: 10px;
  padding: 15px;
  @media screen and (max-width: 550px) {
    transform: translateX(-135px);
  }
`;

const Link = styled.p`
  cursor: pointer;
  color: ${(p) => p.theme.colors.primary500};
  padding: 10px;
  user-select: none;
  font-weight: 700;
  font-size: 0.875rem;
  width: 100%;
  text-align: left;
`;
