import {Pagination, PaginationParams} from '@src/models/pagination';
import {api} from './client';
import {AppNotification} from '@src/models/notification';

export namespace NotificationsApi {
  export const getNotifications = async (
    params: PaginationParams,
    token: string
  ): Promise<Pagination<AppNotification>> => {
    const res = await api.get<Pagination<AppNotification>>(`notifications`, {
      params: {
        page: params.page,
        limit: params.limit,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  };

  export const readNotification = async (
    notificationId: string
  ): Promise<AppNotification> => {
    const res = await api.get<AppNotification>(
      `notifications/${notificationId}`
    );
    return res.data;
  };
  export const readAllNotifications = async () => {
    const res = await api.post('notifications');
    return res.data;
  };
}
