import {NotificationsApi} from '@src/api/notifications-api';
import Image from 'next/image';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {useInfiniteQuery, useMutation, useQueryClient} from 'react-query';
import styled from 'styled-components';
import {format} from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
import {ListSpinner} from '@src/common/ui/ListSpinner';
import {PaginationLogic} from '@src/commonlogic/PaginationLogic';
import {useDataLength} from '@src/commonhooks/useDataLenght';
import {useTranslation} from 'next-i18next';
import {toast} from '@src/utils/toast';
import {parseError} from '@src/utils/error';
import Link from 'next/link';

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 667px;
  max-width: 100%;
  height: 624px;
  border-radius: 20px;
  box-shadow: 4px 4px 20px 0px rgba(206, 207, 242, 0.5);
  z-index: 998;
  position: absolute;
  transform: translate(-84%, 8%);
  background-color: ${(p) => p.theme.colors.neutral100};
  padding-right: 24px;
  padding-left: 24px;
  padding-top: 18px;
  padding-bottom: 18px;
  align-items: flex-start;

  @media screen and (max-width: 1200px) {
    position: absolute;
    transform: translate(0px, 8%);
    right: 0px;
  }
`;

const ContainerTitle = styled.p`
  color: ${(p) => p.theme.colors.neutral800};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 17px;
  align-items: center;
`;

const ImageWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const NotificationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  position: relative;
`;

const NotificationContainer = styled(Link)`
  width: 95%;
  margin-left: 15px;
  min-height: 80px;
  display: flex;
  border-radius: 15px;
  flex-direction: column;
  box-shadow: 4px 4px 20px 0px rgba(206, 207, 242, 0.5);
  margin-bottom: 12px;
  padding: 7px 14px 7px 14px;
  z-index: 999;
`;

const NotificationSpacedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SingleNotificationTitle = styled.p`
  color: ${(p) => p.theme.colors.primary500};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const NewNotification = styled.p`
  color: ${(p) => p.theme.colors.secondary400};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.5px;
`;

const NotificationContent = styled.p<{isSeen: boolean}>`
  color: ${(p) =>
    p.isSeen ? p.theme.colors.primary400 : p.theme.colors.primary500};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.5px;
`;

const DateText = styled.p`
  color: ${(p) => p.theme.colors.primary400};
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
`;

// const OriginText = styled.p<{isSeen: boolean}>`
//   color: ${(p) =>
//     p.isSeen ? p.theme.colors.primary400 : p.theme.colors.primary500};
//   font-family: Poppins;
//   font-size: 12px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 120%;
// `;

const ReadAllText = styled.p`
  color: ${(p) => p.theme.colors.violet600};
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-top: 0;
  cursor: pointer;
  user-select: none;
  text-align: end;
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const NotificationsPopup = ({setOpen}: Props) => {
  const [token, setToken] = useState('');
  useEffect(() => {
    const tmpToken = localStorage.getItem('jwt');
    if (tmpToken) {
      setToken(tmpToken);
    }
  }, []);

  const {t} = useTranslation();
  const {data, hasNextPage, fetchNextPage} = useInfiniteQuery(
    ['notifications'],
    ({pageParam = 1}) => {
      return NotificationsApi.getNotifications({page: pageParam}, token);
    },
    {
      getNextPageParam: PaginationLogic.handleGetNextParam,
      enabled: token !== '',
      refetchOnWindowFocus: true,
    }
  );
  const queryClient = useQueryClient();

  const dataLength = useDataLength(data);

  const {mutate: readAllMutate} = useMutation({
    mutationFn: NotificationsApi.readAllNotifications,
    onSuccess() {
      queryClient.invalidateQueries(['notifications']);
      queryClient.invalidateQueries(['notifications-not-infinite']);
    },
    onError(e) {
      toast.error(parseError(e).message);
    },
  });

  const readAllNotifications = useCallback(async () => {
    readAllMutate();
  }, [readAllMutate]);

  const {mutate: readMutate} = useMutation({
    mutationFn: NotificationsApi.readNotification,
    onSuccess() {
      queryClient.invalidateQueries(['notifications']);
      queryClient.invalidateQueries(['notifications-not-infinite']);
    },
  });
  const onNotificationClick = (id: string) => {
    readMutate(id);
  };

  const getUrl = (url?: string | null): string => {
    if (!url) {
      return '';
    }
    if (url.startsWith('http')) {
      return url;
    }
    if (url.startsWith('/')) {
      return url;
    }
    return `/${url}`;
  };

  return (
    <Container>
      <Row>
        <ImageWrapper onClick={() => setOpen(false)}>
          <Image
            width={16}
            height={20}
            src={'/icons/chevron-right-black.svg'}
            alt="close"
          />
        </ImageWrapper>
      </Row>
      <TitleWrapper>
        <ContainerTitle>{t('common:notifications:title')}</ContainerTitle>

        <ReadAllText onClick={readAllNotifications}>
          {t('common:notifications:read-all')}
        </ReadAllText>
      </TitleWrapper>
      <NotificationsWrapper>
        <InfiniteScroll
          hasMore={hasNextPage || false}
          next={fetchNextPage}
          dataLength={dataLength}
          loader={<ListSpinner />}
        >
          {data?.pages?.map((pg) => {
            return pg.data.map((el) => (
              <NotificationContainer
                onClick={() => onNotificationClick(el.id)}
                target={
                  el.url
                    ? el.url.startsWith('http')
                      ? '_blank'
                      : '_self'
                    : '_self'
                }
                href={getUrl(el.url)}
                key={el.id}
              >
                <NotificationSpacedContainer>
                  <SingleNotificationTitle>{el.title}</SingleNotificationTitle>
                  {el.status === 'UNREAD' && (
                    <NewNotification>
                      {t('common:notifications:new')}
                    </NewNotification>
                  )}
                </NotificationSpacedContainer>
                <NotificationSpacedContainer>
                  <NotificationContent isSeen={el.status !== 'UNREAD'}>
                    {el.content}
                  </NotificationContent>
                </NotificationSpacedContainer>
                <NotificationSpacedContainer>
                  <DateText>
                    {format(new Date(el.createdAt), 'dd.MM.yyyy')}
                  </DateText>
                  {/* <OriginText isSeen={el.status !== 'UNREAD'}>
                    {el.city}
                  </OriginText> */}
                </NotificationSpacedContainer>
              </NotificationContainer>
            ));
          })}
        </InfiniteScroll>
      </NotificationsWrapper>
    </Container>
  );
};
