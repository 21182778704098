import {useEffect, useState} from 'react';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';
import {devices} from './screenSizes';

interface NavbarProps {
  userName?: string;
}

const CookieModalWrapper = styled.div`
  width: 550px;
  height: 200px;
  position: fixed;
  bottom: 5px;
  background: #fff;
  left: 0;
  right: 0;
  z-index: 9999;
  margin: 0 auto;
  text-align: center;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
  color: ${(props) => props.theme.colors.primary500};
  @media ${devices.sm} {
    width: 350px;
    height: 300px;
  }
`;
const AcceptCookiesBtn = styled.div`
  border-radius: 30px;
  border: 1px solid var(--300-violet, #e3d6f9);
  background: var(--300-violet, #e3d6f9);
  width: fit-content;
  display: inline-flex;
  padding: 7px 24px;
  cursor: pointer;
`;

export const CookieModal = ({}: NavbarProps) => {
  const [showCookies, setShowCookies] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    const userId = localStorage.getItem('id');
    const cookies = localStorage.getItem('cookies');
    if (cookies) {
      if (JSON.parse(cookies).includes(userId)) {
        setShowCookies(false);
      } else {
        setShowCookies(true);
      }
    } else {
      setShowCookies(true);
    }
  }, []);

  const setCookiesAsAccepted = () => {
    const userId = localStorage.getItem('id');
    const cookies = localStorage.getItem('cookies');
    if (cookies) {
      localStorage.setItem(
        'cookies',
        JSON.stringify([userId, ...JSON.parse(cookies)])
      );
    } else {
      localStorage.setItem('cookies', JSON.stringify([userId]));
    }
    setShowCookies(false);
  };

  return (
    <>
      {showCookies && (
        <CookieModalWrapper>
          <div className="text-sb18" style={{marginBottom: '10px'}}>
            {t('common:cookies:title')}
          </div>
          <div className="text-m14" style={{marginBottom: '10px'}}>
            {' '}
            {t('common:cookies:text')}
          </div>

          <AcceptCookiesBtn onClick={setCookiesAsAccepted} className="text-r12">
            {t('common:cookies:button')}
          </AcceptCookiesBtn>
        </CookieModalWrapper>
      )}
    </>
  );
};
