export const sizes = {
  mini:'380px',
  '2xs': '450px',
  xs: '550px',
  sm: '660px',
  md: '768px',
  rg:'900px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
};

export const devices = {
  mini:`screen and (max-width: ${sizes.mini})`,
  '2xs': `screen and (max-width: ${sizes['2xs']})`,
  xs: `screen and (max-width: ${sizes.xs})`,
  sm: `screen and (max-width: ${sizes.sm})`,
  md: `screen and (max-width: ${sizes.md})`,
  rg:`screen and (max-width: ${sizes.rg})`,
  lg: `screen and (max-width: ${sizes.lg})`,
  xl: `screen and (max-width: ${sizes.xl})`,
  '2xl': `screen and (max-width: ${sizes['2xl']})`,
};
