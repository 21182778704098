import Image from 'next/image';
import Link from 'next/link';
import {ReactNode, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import PeopleSvg from '../../public/icons/people.svg';
import MembSvg from '../../public/icons/memb.svg';
import MessageSvg from '../../public/icons/message.svg';
import ProfileSvg from '../../public/icons/profile.svg';
import NewsSvg from '../../public/icons/news.svg';
import {imagesService} from '@src/features/images/logic/images-service';
import {SettingsButton} from './SettingsButton';
import {useRouter} from 'next/router';
import {useQuery} from 'react-query';
import {CookieModal} from '@src/common/CookieModal';
import {useTranslation} from 'next-i18next';
import {NotificationsPopup} from '@src/features/notifications/NotificationsPopup';
import {NotificationsApi} from '@src/api/notifications-api';
import {PaginationLogic} from '@src/commonlogic/PaginationLogic';
import i18n from 'i18next';
import {devices} from '@src/common/screenSizes';

const HEADER_HEIGHT = '80px';

const MOBILE_MENU_BREAKPOINT = '750px';

const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.neutral100};
  padding: 2rem;
  height: ${HEADER_HEIGHT};
  box-shadow: ${(props) => props.theme.shadow.shadow1};
  @media ${devices.sm} {
    padding: 1rem;
  }
`;

const HeaderNavList = styled.ul`
  display: flex;
  gap: 2rem;
  list-style: none;
  @media screen and (max-width: 500px) {
    gap: 10px;
  }
  @media screen and (max-width: 400px) {
    gap: 10px;
  }
`;
const HeaderNavListItem = styled.li`
  display: flex;
`;
const HeaderNavListItemMobileHamburger = styled.li`
  display: flex;
  @media (min-width: ${MOBILE_MENU_BREAKPOINT}) {
    display: none;
  }
`;
const HeaderNavListButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
const HeaderNavListButtonMobileHamburger = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const MainContainer = styled.div`
  min-height: calc(100vh - ${HEADER_HEIGHT});
  display: grid;
  grid-template-columns: auto 1fr;
`;

const Aside = styled.aside`
  z-index: 9999;
  width: 240px;
  height: 100%;
  background-color: ${(props) => props.theme.colors.primary500};
  @media (max-width: ${MOBILE_MENU_BREAKPOINT}) {
    transition: all 0.5s !important;
    position: absolute;
  }
`;

const ClosingIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
  cursor: pointer;
  @media (max-width: ${MOBILE_MENU_BREAKPOINT}) {
    display: block;
  }
`;

const PrimaryNavList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;

const PrimaryNavItem = styled.li<{toHighlight: boolean}>`
  list-style: none;
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${(props) =>
    props.toHighlight
      ? props.theme.colors.neutral100
      : props.theme.colors.primary400};
  background-color: ${(p) =>
    p.toHighlight ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};
  border-radius: 5px;
  padding: 10px;
  &:hover {
    color: ${(props) => props.theme.colors.neutral100};
  }
`;
const PrimaryNavLink = styled(Link)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const Main = styled.main`
  padding: 1rem;
  @media (max-width: ${MOBILE_MENU_BREAKPOINT}) {
    margin: 0 auto;
    /* width: 100%; */
    width: 100vw;
  }
`;

interface UserLayoutProps {
  children: ReactNode;
}

export const UserLayout = ({children}: UserLayoutProps) => {
  const router = useRouter();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [notifications, setNotificationsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const [token, setToken] = useState('');
  const {t} = useTranslation();

  const PrimaryNavigationItems = [
    {href: '/memb-community', Icon: PeopleSvg, label: t('pages:community')},
    {
      href: '/memb-community?topicType=NEWS',
      Icon: NewsSvg,
      label: t('pages:news'),
    },
    {href: '/memb', Icon: MembSvg, label: t('pages:memb')},
    {href: '/messages/chats', Icon: MessageSvg, label: t('pages:messages')},
    // {href: '/profile', Icon: ProfileSvg, label: t('pages:my-profile')},
  ];

  const GuestNavigationItems = [
    {href: '/sign-in', Icon: ProfileSvg, label: t('common:login')},
    {href: '/sign-up', Icon: ProfileSvg, label: t('common:register')},
  ];

  useEffect(() => {
    const tmpToken = localStorage.getItem('jwt');
    if (tmpToken) {
      setToken(tmpToken);
    }
  }, [token]);

  const {data: avatar} = useQuery(
    ['avatar'],
    () => imagesService.getUserAvatar(),
    {
      staleTime: 1 * 60 * 1000, // 1 minute
      cacheTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const {data} = useQuery(
    ['notifications-not-infinite'],
    ({pageParam = 1}) => {
      return NotificationsApi.getNotifications({page: pageParam}, token);
    },
    {
      getNextPageParam: PaginationLogic.handleGetNextParam,
      enabled: token !== '',
      staleTime: 100,
    }
  );

  const newNotifications = useMemo(() => {
    return data?.data?.filter((el) => el.status === 'UNREAD');
  }, [data?.data]);

  const toHighlightTab = useMemo(() => {
    if (router.route.includes('profile')) {
      return '/profile';
    } else if (router.route.includes('messages')) {
      return '/messages';
    } else if (
      router.route.includes('/memb') &&
      !router.route.includes('memb-community')
    ) {
      return '/memb';
    } else if (router.route.includes('news')) {
      return '/news';
    } else if (router.route.includes('memb-community')) {
      if (router.query.topicType === 'NEWS') {
        return '/memb-community?topicType=NEWS';
      }
      return '/memb-community';
    }

    return null;
  }, [router.route, router.query]);

  const handleAvatarClick = () => {
    router.push('/profile');
  };

  return (
    <div>
      <Header>
        <Link href="/">
          <Image
            alt="Membplace logo"
            src={'/icons/logo.svg'}
            width={90}
            height={60}
          />
        </Link>
        <nav>
          {token && (
            <HeaderNavList>
              <HeaderNavListItem>
                <HeaderNavListButton>
                  <SettingsButton
                    isOpen={isSettingsOpen}
                    setIsOpen={() => {
                      setNotificationsOpen(false);
                      setIsSettingsOpen((prev) => !prev);
                    }}
                  />
                </HeaderNavListButton>
              </HeaderNavListItem>
              <HeaderNavListItem>
                <HeaderNavListButton
                  onClick={() => {
                    setNotificationsOpen((prev) => !prev);
                    setIsSettingsOpen(false);
                  }}
                >
                  <Image
                    alt="Notifications"
                    src={'/icons/bell.svg'}
                    width={24}
                    height={24}
                  />
                  {newNotifications && newNotifications.length > 0 && (
                    <Image
                      alt="Ellipse"
                      src={'/icons/ellipse.svg'}
                      width={12}
                      height={12}
                      style={{
                        position: 'absolute',
                        transform: 'translate(140%, -270%)',
                      }}
                    />
                  )}
                </HeaderNavListButton>
              </HeaderNavListItem>
              {notifications && (
                <NotificationsPopup setOpen={setNotificationsOpen} />
              )}
              <HeaderNavListItem>
                <HeaderNavListButton
                  onClick={handleAvatarClick}
                  style={{width: 44, padding: 0}}
                >
                  {avatar ? (
                    <Image
                      alt="Avatar"
                      src={avatar}
                      width={44}
                      height={44}
                      style={{
                        objectFit: 'cover',
                        borderRadius: '999px',
                      }}
                    />
                  ) : (
                    <Image
                      alt="Avatar"
                      src={'/icons/avatar.svg'}
                      width={44}
                      height={44}
                    />
                  )}
                </HeaderNavListButton>
              </HeaderNavListItem>
              <HeaderNavListItemMobileHamburger>
                <HeaderNavListButtonMobileHamburger
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <Image
                    alt="Avatar"
                    src={'/icons/hamburger.svg'}
                    width={24}
                    height={24}
                  />
                </HeaderNavListButtonMobileHamburger>
              </HeaderNavListItemMobileHamburger>
            </HeaderNavList>
          )}
        </nav>
      </Header>
      <MainContainer>
        <Aside style={{left: mobileMenuOpen ? '0' : '-240px'}}>
          <ClosingIcon onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z"
                fill="#2F3071"
              />
              <path
                d="M8.96371 0C7.19086 0 5.45782 0.525712 3.98374 1.51066C2.50967 2.4956 1.36077 3.89554 0.682326 5.53345C0.00388384 7.17135 -0.173628 8.97365 0.172239 10.7124C0.518106 12.4512 1.37182 14.0484 2.62541 15.302C3.87901 16.5556 5.47619 17.4093 7.21498 17.7552C8.95377 18.101 10.7561 17.9235 12.394 17.2451C14.0319 16.5667 15.4318 15.4178 16.4168 13.9437C17.4017 12.4696 17.9274 10.7366 17.9274 8.96371C17.9249 6.58716 16.9797 4.30866 15.2992 2.62818C13.6188 0.947704 11.3403 0.00250968 8.96371 0ZM12.2096 11.2339C12.2737 11.298 12.3245 11.3741 12.3592 11.4578C12.3938 11.5415 12.4117 11.6312 12.4117 11.7218C12.4117 11.8124 12.3938 11.9021 12.3592 11.9858C12.3245 12.0695 12.2737 12.1455 12.2096 12.2096C12.1455 12.2737 12.0695 12.3245 11.9858 12.3592C11.9021 12.3938 11.8124 12.4117 11.7218 12.4117C11.6312 12.4117 11.5415 12.3938 11.4578 12.3592C11.3741 12.3245 11.298 12.2737 11.2339 12.2096L8.96371 9.93851L6.69348 12.2096C6.62942 12.2737 6.55336 12.3245 6.46966 12.3592C6.38596 12.3938 6.29625 12.4117 6.20565 12.4117C6.11505 12.4117 6.02534 12.3938 5.94164 12.3592C5.85793 12.3245 5.78188 12.2737 5.71782 12.2096C5.65375 12.1455 5.60294 12.0695 5.56826 11.9858C5.53359 11.9021 5.51575 11.8124 5.51575 11.7218C5.51575 11.6312 5.53359 11.5415 5.56826 11.4578C5.60294 11.3741 5.65375 11.298 5.71782 11.2339L7.98891 8.96371L5.71782 6.69348C5.58843 6.5641 5.51575 6.38862 5.51575 6.20565C5.51575 6.02267 5.58843 5.84719 5.71782 5.71781C5.8472 5.58843 6.02268 5.51575 6.20565 5.51575C6.38862 5.51575 6.5641 5.58843 6.69348 5.71781L8.96371 7.98891L11.2339 5.71781C11.298 5.65375 11.3741 5.60293 11.4578 5.56826C11.5415 5.53359 11.6312 5.51575 11.7218 5.51575C11.8124 5.51575 11.9021 5.53359 11.9858 5.56826C12.0695 5.60293 12.1455 5.65375 12.2096 5.71781C12.2737 5.78188 12.3245 5.85793 12.3592 5.94163C12.3938 6.02533 12.4117 6.11505 12.4117 6.20565C12.4117 6.29624 12.3938 6.38596 12.3592 6.46966C12.3245 6.55336 12.2737 6.62941 12.2096 6.69348L9.93852 8.96371L12.2096 11.2339Z"
                fill="white"
              />
            </svg>
          </ClosingIcon>
          <nav>
            {token && (
              <PrimaryNavList>
                {PrimaryNavigationItems.map((item) => (
                  <PrimaryNavItem
                    key={item.href}
                    toHighlight={item.href === toHighlightTab}
                  >
                    <PrimaryNavLink href={item.href}>
                      <item.Icon width={24} height={24} />
                      <span>{item.label}</span>
                    </PrimaryNavLink>
                  </PrimaryNavItem>
                ))}
              </PrimaryNavList>
            )}
            {!token && (
              <PrimaryNavList>
                {GuestNavigationItems.map((item) => (
                  <PrimaryNavItem
                    key={item.href}
                    toHighlight={item.href === toHighlightTab}
                  >
                    <PrimaryNavLink href={item.href}>
                      <item.Icon width={24} height={24} />
                      <span>{item.label}</span>
                    </PrimaryNavLink>
                  </PrimaryNavItem>
                ))}
              </PrimaryNavList>
            )}
          </nav>
        </Aside>

        <Main>
          {children} <CookieModal />
        </Main>
      </MainContainer>
    </div>
  );
};
